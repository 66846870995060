import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from 'components/layouts/main/Layout';
import FirstScreen from 'components/blog/FirstScreen';
import SecondScreen from 'components/blog/SecondScreen';

export default class BlogPage extends React.Component<any> {
  render() {
    const { data } = this.props;
    const withoutDummyPost = data.allMarkdownRemark.edges
      .filter((p: any) => p.node.frontmatter.title !== 'dummy_post')
      .filter((p: any) => p.node.fields.slug.indexOf('/blog/') === 0);

    return (
      <Layout location={this.props.path}>
        <Helmet>
          <title>Blog</title>
        </Helmet>
        <FirstScreen posts={withoutDummyPost}></FirstScreen>
        <SecondScreen posts={withoutDummyPost}></SecondScreen>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD.MM.YY")
            author
            category
            mainImage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            isFeatured
          }
          fields {
            slug
          }
          excerpt(pruneLength: 150)
        }
      }
    }
  }
`;
